import './TimelineItem.scss';

const TimelineItem = ({ onClick, text, index, currentSlide }) => {
  const menuItemClass = `timeline-menu-item ${index <= currentSlide ? 'timeline-menu-item--active' : ''} `;
  const menuItemPathClass = `timeline-menu-item__path timeline-menu-item-path ${
    index === 1 ? 'timeline-menu-item-path--invisible' : ''
  }`;

  const changeSlideHandler = () => onClick(index);

  return (
    <div className={menuItemClass}>
      <div className={menuItemPathClass}>
        <div className='timeline-menu-item-path__line' />
      </div>
      <div className='timeline-menu-item__target timeline-menu-item-target' onClick={changeSlideHandler}>
        <div className='timeline-menu-item-target__dot' />
        <div className='timeline-menu-item-target__title'>{text}</div>
      </div>
    </div>
  );
};

export { TimelineItem };
