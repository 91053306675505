import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const LegalNotices = () => {
    
    const { t } = useTranslation(['privacyAgreement']);
    const data = t('body');
    const getSanitizedData = () => ({
        __html: DOMPurify.sanitize(data)
      });

    return (
        <div
        dangerouslySetInnerHTML={getSanitizedData()}
        />
    );
};

export { LegalNotices };