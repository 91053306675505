import React from 'react';
import { useRef } from "react";

import { useTranslation } from 'react-i18next';

import './ContactUsForms.scss';
import { CONTACT_LINKS } from '../../shared/constants';

import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

const ContactUsCorporateTraining = ({onSuccessSubmit}) => {

    const { t } = useTranslation(['contactUsForms', 'language']);

    const first_name = useRef();
    const last_name = useRef();
    const your_position = useRef();
    const email_address = useRef();
    const company_name = useRef();
    const city = useRef();
    const country = useRef();
    const number_of_employees_in_your_company = useRef();

    const requiredFieldsOrderFromForm = [
        {'first_name': first_name},
        {'last_name': last_name},
        {'your_position': your_position},
        {'email_address': email_address},
        {'company_name': company_name},
        {'city': city},
        {'country': country},
        {'number_of_employees_in_your_company': number_of_employees_in_your_company}
    ];

    const putFocusOnField = (fieldName) => {
        if (fieldName.current) {
            fieldName.current.focus();
        }
    };
    
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            your_position: '',
            email_address: '',
            phone_number: '',
            company_name: '',
            city: '',
            country: '',
            web_address: '',
            number_of_employees_in_your_company: '',
            message: ''
        },
        onSubmit: (data) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            const fetchURL = `${window.location.origin}/${t('langCode', { ns: 'language' })}/${CONTACT_LINKS.CORPORATE}`;

            fetch(fetchURL, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                    let errorFields = Object.keys(res);

                    if (res.status === "success") {
                        onSuccessSubmit();
                        formik.resetForm();
                    } else {
                        Object.entries(res).forEach( ([key, value]) => { formik.setFieldError(key, value) } )

                        for (let i=0; i<requiredFieldsOrderFromForm.length; i++) {
                            let requiredFieldName = Object.keys(requiredFieldsOrderFromForm[i])[0];
                            if (errorFields.indexOf(requiredFieldName) >= 0) {
                                let requiredField = requiredFieldsOrderFromForm[i];
                                putFocusOnField(requiredField[requiredFieldName]);
                                break;
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <div className='form-container-contact-field-block__error-message'><small className="p-error">{formik.errors[name]}</small></div>;
    };

    const validateNumberEmployeesHandler = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            formik.setFieldValue('number_of_employees_in_your_company', e.target.value);
        }
    };
    
    return (
        <>
        <div className="form-container">                                            
            <form onSubmit={formik.handleSubmit} className='form-container__contact form-container-contact'>
                <div className='form-container-contact__subtitle'>{t('yourInformation')}</div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('first_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="first_name">{t('firstName')}:&nbsp;*</label>
                        <InputText id="first_name" name="first_name" value={formik.values.first_name} ref={first_name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('first_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('last_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="last_name">{t('lastName')}:&nbsp;*</label>
                        <InputText id="last_name" name="last_name" value={formik.values.last_name} ref={last_name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('last_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('your_position')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="your_position">{t('jobFunction')}:&nbsp;*</label>
                        <InputText id="your_position" name="your_position" value={formik.values.your_position} ref={your_position} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('your_position') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('email_address')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="email_address">{t('emailAddress')}:&nbsp;*</label>
                        <InputText id="email_address" name="email_address" value={formik.values.email_address} ref={email_address} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email_address') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('phone_number')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="phone_number">{t('telephoneNumber')}:</label>
                        <InputText id="phone_number" name="phone_number" value={formik.values.phone_number} onChange={formik.handleChange} />
                    </div>
                </div>
                <div className='form-container-contact__subtitle'>{t('yourCompany')}</div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('company_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="company_name">{t('companyName')}:&nbsp;*</label>
                        <InputText id="company_name" name="company_name" value={formik.values.company_name} ref={company_name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('company_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('city')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="city">{t('cityTown')}:&nbsp;*</label>
                        <InputText id="city" name="city" value={formik.values.city} ref={city} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('city') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('country')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="country">{t('country')}:&nbsp;*</label>
                        <InputText id="country" name="country" value={formik.values.country} ref={country} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('country') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('web_address')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="web_address">{t('webSite')}:</label>
                        <InputText id="web_address" name="web_address" value={formik.values.web_address} onChange={formik.handleChange} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('number_of_employees_in_your_company')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="number_of_employees_in_your_company">{t('numberEmployees')}:&nbsp;*</label>
                        <InputText id="number_of_employees_in_your_company" name="number_of_employees_in_your_company" value={formik.values.number_of_employees_in_your_company} ref={number_of_employees_in_your_company} onChange={validateNumberEmployeesHandler} className={classNames({ 'p-invalid': isFormFieldValid('number_of_employees_in_your_company') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('message')}
                    <div className='form-container-contact-field-block__field form-container-contact-field-block-field--full-size'>
                        <label htmlFor="message">{t('whatCan2')}:</label>
                        <InputTextarea id="message" rows={5} value={formik.values.message} onChange={formik.handleChange} />
                    </div>
                </div>
                <Button type="submit" label={t('send')} className="form-container-contact__btn" />
            </form>
        </div>
        </>
    );
};

export { ContactUsCorporateTraining };