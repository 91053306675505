import './IntroSlide.scss';

import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

import ENVideoPoster from '../../assets/intro/EN-video-cover.png';
import ENVideoPoster1 from '../../assets/intro/EN-video-cover1.png';
import FRVideoPoster from '../../assets/intro/FR-video-cover.png';
import FRVideoPoster1 from '../../assets/intro/FR-video-cover1.png';
import ENVideo from '../../assets/intro/EN-video.mp4';
import FRVideo from '../../assets/intro/FR-video.mp4';
import { Slide } from '../../shared/components/Slide/Slide';
import { Title } from '../../shared/components/Title/Title';
import { BROWSER_NAMES, CDN_LINK } from '../../shared/constants';
import { getBrowserName } from '../../shared/utils';

const IntroSlide = React.memo(() => {
  const { t } = useTranslation(['homepage', 'language']);
  const viewportRef = useRef(null);
  const { enterCount } = useInViewport(viewportRef);
  const INTRODUCTION_VIDEOS = {
    fr: {
      video: FRVideo,
      video_cover: FRVideoPoster,
      video_cover1: FRVideoPoster1
    },
    default: {
      video: ENVideo,
      video_cover: ENVideoPoster,
      video_cover1: ENVideoPoster1
    },
  };

  const availableLanguagesVideos = Object.keys(INTRODUCTION_VIDEOS);
  const language = t('langCode', { ns: 'language' });
  const introVideoFound = availableLanguagesVideos.filter(languageVideo => languageVideo === language);
  const availableVideo = introVideoFound.length === 0 ? INTRODUCTION_VIDEOS.default : INTRODUCTION_VIDEOS[language];
  const [showVideoControls, setShowVideoControls] = useState(false);
  const isChromeBrowser = getBrowserName() === BROWSER_NAMES.CHROME;

  const setControlsHandler = (value) => {
    setShowVideoControls(value);
  }

  return (
    <Slide className='introduction-slide' ref={viewportRef}>
      <section className='introduction-slide__introduction'>
        <Title text={t('introductionSection.title')} />
        {t('introductionSection.paragraphs', { returnObjects: true }).map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </section>
      <section className='intro-video'>
        {enterCount > 0 && isChromeBrowser && (
          <video preload='metadata' controls={showVideoControls} onMouseOver={() => setControlsHandler(true)}
          onMouseOut={() => setControlsHandler(false)} className='intro-video__image' poster={CDN_LINK + availableVideo.video_cover1}>
            <source src={CDN_LINK + availableVideo.video} type='video/mp4' />
          </video>
        )}
        {enterCount > 0 && !isChromeBrowser && (
          <video preload='metadata' controls className='intro-video__image' poster={CDN_LINK + availableVideo.video_cover}>
            <source src={CDN_LINK + availableVideo.video} type='video/mp4' />
          </video>
        )}
      </section>
    </Slide>
  );
});

export { IntroSlide };
