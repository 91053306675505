import './Footer.scss';

import { useRef } from 'react';

import { useInViewport } from 'react-in-viewport';
import { CDN_LINK } from '../../shared/constants';

import ScrollIcon from '../../assets/hero/scroll-animation.gif';

const Footer = () => {
  const viewportRef = useRef(null);
  const { inViewport, enterCount } = useInViewport(viewportRef);

  const scrollDownPage = () => {
    window.scrollTo({top: 400, behavior: 'smooth'});
  }

  return (
    <div className={`hero-footer ${inViewport && enterCount === 1 ? 'hero-footer--animated' : ''}`} ref={viewportRef}>
      <img className='hero-footer__scroll-image' src={CDN_LINK + ScrollIcon} alt='Scroll Icon' onClick={scrollDownPage}/>
    </div>
  );
};

export { Footer };
