import './ContactUsSuccess.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/components/Button/Button';
import { BUTTON_TYPES, CDN_LINK } from '../../shared/constants';
import SuccessIcon from '../../assets/contact-us/success-dot.svg';

const ContactUsSuccess = ({onClickCloseButton}) => {
    const { t } = useTranslation(['contactUsForms']);

    return (
        <div className='success-message'>
            <div className='success-message__image'>
                <img src={CDN_LINK + SuccessIcon} alt='Success Icon' />
            </div>
            <div className='success-message__text'>{t('submitSuccess')}</div>
            <div className='success-message__btn'>
                <Button text={t('close')} type={BUTTON_TYPES.MAIN} onClick={onClickCloseButton} />
            </div>
        </div>
    );
};

export { ContactUsSuccess };