import './ReferencesSlide.scss';

import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

import { Slide } from '../../shared/components/Slide/Slide';
import { Title } from '../../shared/components/Title/Title';
import { TabsView } from './TabsView/TabsView';

const ReferencesSlide = React.memo(() => {
  const { t } = useTranslation(['homepage']);
  const viewportRef = useRef(null);
  const { enterCount } = useInViewport(viewportRef);

  return (
    <Slide className='references-slide' ref={viewportRef}>
      <section className='references-slide__introduction'>
        <Title text={t('referencesSection.title')} />
        {t('referencesSection.paragraphs', { returnObjects: true }).map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </section>
      <section className='references-tabs-container'>
        <TabsView shouldStartRendering={enterCount > 0} />
      </section>
    </Slide>
  );
});

export { ReferencesSlide };
