import './SolutionSlide.scss';

import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

import { Footer } from '../../shared/components/Footer/Footer';
import { Slide } from '../../shared/components/Slide/Slide';
import {
  SwiperCarousel,
} from '../../shared/components/SwiperCarousel/SwiperCarousel';
import { Title } from '../../shared/components/Title/Title';

const SolutionSlide = React.memo(
  ({ onSlideChange, openContactUsModal, openAboutUsPage, openLegalNoticesPage, openPersonalDataPage, openGeneralTermsPage }) => {
    const { t } = useTranslation(['homepage']);
    const viewportRef = useRef(null);
    const { enterCount } = useInViewport(viewportRef);

    return (
      <Slide className='solution-slide' ref={viewportRef}>
        <section className='solution-slide__introduction'>
          <Title text={t('solutionSection.title')} />
        </section>
        <div className='carousel-container'>
          <SwiperCarousel shouldStartRendering={enterCount > 0} onSlideChange={onSlideChange} />
        </div>
        <div className='footer'>
          <Footer
            openContactUsModal={openContactUsModal}
            openAboutUsPage={openAboutUsPage}
            openLegalNoticesPage={openLegalNoticesPage}
            openGeneralTermsPage={openGeneralTermsPage}
            openPersonalDataPage={openPersonalDataPage}
          />
        </div>
      </Slide>
    );
  }
);

export { SolutionSlide };
