import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Layer1Dots } from '../../assets/hero/animation-dots-layer.svg';
import TriangeGlowSrc from '../../assets/hero/triangle-glow.svg';
import './Animation3D.scss';

export const Animation3D = () => {
  // THIS IS COMMENTED AND KEPT IN CASE WE WILL NEED TO MODIFY THE FIRST LAYER IN THE FUTURE
  // const circleSize = '0.8'
  // const circleColor = 'white'
  // const spacer = 2.5
  // const grid = 100
  // const pushHeight = 10
  // Used to bypass react 18 re-render cycle
  // const flag = useRef(false)
  const [isAnimationFinalized, setIsAnimationFinalized] = useState(false);

  // effects
  // Listens and sets the correct state of isAnimationFinalized when all the animation end
  useEffect(() => {
    const dotsLayerSvg = document.getElementById('layer1');

    const handleAnimationEnd = (e) => e.animationName === 'rotate' && setIsAnimationFinalized(true);

    dotsLayerSvg.addEventListener('animationend', handleAnimationEnd);

    return () => dotsLayerSvg.removeEventListener('animationend', handleAnimationEnd);
  }, []);

  // Add dots rising effect on hover
  useEffect(() => {
    if (!isAnimationFinalized || window.innerWidth < 900) return;

    const $svg = document.getElementById('layer1');
    const $circles = document.querySelectorAll('#layer1-dots circle');
    const $cursor = document.getElementById('cursor');

    const onMouseMove = throttle((e) => {
      const { x: svgX, y: svgY, width: svgWidth, height: svgHeight } = $svg.getBoundingClientRect();

      if (e.pageX >= svgX && e.pageX <= svgX + svgWidth && e.pageY >= svgY && e.pageY <= svgY + svgHeight) {
        $cursor.style.left = e.clientX + 'px';
        $cursor.style.top = e.clientY + 'px';

        $circles.forEach((circle) => {
          const bbox = circle.getBoundingClientRect();
          const brush = $cursor.getBoundingClientRect();

          const deltaX = Math.abs(brush.x - bbox.x);
          const deltaY = Math.abs(brush.y - bbox.y);
          const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

          if (distance < brush.width - 10) {
            circle.setAttribute('class', 'active');
          } else {
            circle.getAttribute('class')?.includes('active') && circle.removeAttribute('class');
          }
        });
      }
    }, 2);

    document.addEventListener('mousemove', onMouseMove);

    const onMouseOut = () => {
      document.querySelectorAll('.active').forEach((el) => el.removeAttribute('class'));
    };
    $svg.onmouseout = onMouseOut;
    $svg.onmouseleave = onMouseOut;

    return () => {
      $svg.onmouseleave = null;
      $svg.onmouseout = null;
    };
  }, [isAnimationFinalized]);

  // Add after animation listeners
  useEffect(() => {
    if (!isAnimationFinalized) return;

    const multiple = 35;
    const $mouseOverContainer = document.getElementById('animation-container');
    const $triangle = document.getElementById('triangle');

    $mouseOverContainer.onmousemove = (e) => {
      let boxTriangle = $triangle.getBoundingClientRect();
      let calcX = -(e.clientY - boxTriangle.y - boxTriangle.height / 2) / (multiple * 0.75);
      let calcY = (e.clientX - boxTriangle.x - boxTriangle.width / 2) / (multiple * 0.75);

      let calcXLayer = -(e.clientY - boxTriangle.y - boxTriangle.height / 2) / (multiple * 1.5);
      let calcYLayer = (e.clientX - boxTriangle.x - boxTriangle.width / 2) / (multiple * 1.5);

      $triangle.classList.remove('triangle-animation');
      $triangle.classList.add('triangle-after-animation');
      $triangle.style.transform = `rotate3d(-0.5, 0.2, 0.2, 0) rotateX(${calcX}deg) rotateY(${calcY}deg) translateY(50%)`;

      document.querySelectorAll('.layer-animation').forEach((el) => {
        el.classList.remove('layer-animation');
        el.classList.add('layer-after-animation');
      });
      document.querySelectorAll('.layer-after-animation').forEach((el) => {
        el.style.transform = `rotate3d(-0.5, 0.2, 0.2, 0) rotateX(${calcXLayer}deg) rotateY(${calcYLayer}deg) translateY(50%)`;
      });
    };
  }, [isAnimationFinalized]);

  /** THIS IS KEPT HERE IN CASE WE NEED TO MODIFY THE FIRST LAYER IN THE FUTURE
   * This effect adds all the dots with their opacity based on the
   */
  // Adds the dots to the layer1 svg
  // useEffect(() => {
  //   if (flag.current || !dotsLayer) return
  //   const $layer1Dots = document.getElementById('layer1-dots')!
  //   const layer1Path = document.getElementById(
  //     'path-4'
  //   )! as unknown as SVGGeometryElement
  //   const layer1Path5 = document.getElementById(
  //     'path-5'
  //   )! as unknown as SVGGeometryElement
  //   const layer1Path6 = document.getElementById(
  //     'path-6'
  //   )! as unknown as SVGGeometryElement
  //   const layer1 = document.getElementById(
  //     'layer1'
  //   )! as unknown as SVGSVGElement
  //   let count = +circleSize
  //   const gridLimit = grid - +circleSize

  //   while (count <= gridLimit) {
  //     for (let i = +circleSize; i <= gridLimit; i += +circleSize + spacer) {
  //       const circle = document.createElementNS(
  //         'http://www.w3.org/2000/svg',
  //         'circle'
  //       )
  //       circle.setAttribute('cx', i.toString())
  //       circle.setAttribute('cy', count.toString())
  //       circle.setAttribute('r', circleSize)
  //       circle.setAttribute('fill', circleColor)

  //       $layer1Dots.appendChild(circle)
  //       circlesRef.current.push(circle)
  //       const bbox = circle.getBBox()
  //       // const distanceWho = closestPoint(layer1Path, [bbox.x, bbox.y])
  //       // console.log('distanceWho', distanceWho)
  //       // let point = new DOMPoint(+circle.cx, +circle.cy)
  //       const point = layer1.createSVGPoint()
  //       point.x = +circle.getAttribute('cx')!
  //       point.y = +circle.getAttribute('cy')!

  //       if (!layer1Path.isPointInFill(point)) {
  //         circle.setAttribute('opacity', '0.5')
  //       }

  //       if (layer1Path.isPointInStroke(point)) {
  //         circle.setAttribute('opacity', '0.7')
  //       }

  //       if (!layer1Path5.isPointInFill(point)) {
  //         circle.setAttribute('opacity', '0.3')
  //         // circle.setAttribute('fill', 'red')
  //       }

  //       if (layer1Path5.isPointInStroke(point)) {
  //         circle.setAttribute('opacity', '0.4')
  //         // circle.setAttribute('fill', 'green')
  //       }

  //       if (!layer1Path6.isPointInFill(point)) {
  //         circle.setAttribute('opacity', '0')
  //         // circle.setAttribute('fill', 'red')
  //       }

  //       if (layer1Path6.isPointInStroke(point)) {
  //         circle.setAttribute('opacity', '0.2')
  //         // circle.setAttribute('fill', 'green')
  //       }
  //     }

  //     count += +circleSize + spacer
  //   }

  //   flag.current = true
  // }, [dotsLayer])

  return (
    <div className="animation-3d" id="container">
      <div id="layer">
        <Layer1Dots className="layer-animation" />

        {/* THIS IS KEPT HERE IN CASE IT WILL BE NEEDED TO MODIFY THE FIRST LAYER IN THE FUTUTRE, COMBINED WITH THE COMMENTED useEffect */}
        {/* <svg
          viewBox='0 0 100 100'
          xmlns='http://www.w3.org/2000/svg'
          id='layer1'
          className='layer-animation'
        >
          <defs>
            <path
              d='M32.5917323,275.220204 C22.2506909,268.574208 17.3282025,256.293427 19.6686104,243.001436 L55.5742368,56.3920647 C57.9146447,43.1064339 67.1681596,30.8256522 79.849609,24.179657 C92.5246985,17.5400215 106.700702,17.5400215 117.048103,24.179657 L262.197912,117.484343 C272.532593,124.130338 277.461442,136.41112 275.121034,149.70311 C272.774266,162.988741 263.520751,175.269523 250.839302,181.915518 L69.7775069,275.220204 C63.443142,278.540021 56.7271889,280.19993 50.3101465,280.19993 C43.8803846,280.19993 37.7495335,278.540021 32.5917323,275.220204 Z'
              id='path-2'
              clipPath='url(#circle-opacity)'
            ></path>

            <path
              d='M19.073 83.816C16.377 82.084 15.094 78.882 15.704 75.417L25.064 26.77C25.675 23.306 28.087 20.105 31.393 18.372 34.697 16.641 38.393 16.641 41.09 18.372L78.929 42.696C81.624 44.428 82.909 47.63 82.298 51.095 81.687 54.558 79.274 57.76 75.968 59.492L28.767 83.816C27.116 84.682 25.365 85.114 23.692 85.114 22.016 85.114 20.418 84.682 19.073 83.816Z
      '
              id='path-4'
            ></path>
            <path
              d='M16.379 88.238C13.346 86.289 11.903 82.688 12.589 78.789L23.119 24.061C23.806 20.165 26.52 16.563 30.239 14.614 33.956 12.667 38.114 12.667 41.148 14.614L83.717 41.978C86.748 43.927 88.194 47.529 87.508 51.427 86.819 55.323 84.105 58.925 80.386 60.874L27.285 88.238C25.427 89.212 23.458 89.699 21.576 89.699 19.69 89.699 17.892 89.212 16.379 88.238Z'
              id='path-5'
            ></path>

            <path
              d='M6.394 97.543C2.48 95.028.617 90.381 1.503 85.35L15.091 14.73C15.977 9.702 19.479 5.054 24.278 2.539 29.075.026 34.44.026 38.356 2.539L93.286 37.849C97.198 40.365 99.063 45.012 98.177 50.042 97.289 55.07 93.787 59.718 88.988 62.233L20.466 97.543C18.069 98.8 15.528 99.428 13.099 99.428 10.666 99.428 8.346 98.8 6.394 97.543Z'
              id='path-6'
            ></path>

            <clipPath id='mask'>
              <use xlinkHref='#path-2' x='10' y='7' width='36' />
            </clipPath>

            <clipPath id='circle-opacity'>
              <circle cx='140' cy='140' r='1' id='dotsVisibilityPath' />
            </clipPath>
          </defs>
          <g
            id='layer1-dots'
            clipPath='url(#mask)'
            style={{ transform: 'translate(-20%, -40)' }}
          ></g>
        </svg> */}
      </div>

      <img src={TriangeGlowSrc} alt="Triangle" id="triangle" className="triangle-animation" />

      <div className="cursor" id="cursor"></div>
    </div>
  );
};
