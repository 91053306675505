import './Header.scss';

import { useRef } from 'react';

import { Menu } from 'primereact/menu';
import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';
import { CDN_LINK } from '../../shared/constants';

import Logo from '../../assets/hero/LA-brand-logo-original.svg';
import LanguageIcon from '../../assets/hero/language-icon.svg';
import translations from '../../assets/translations.json';
import { Button } from '../../shared/components/Button/Button';

const Header = () => {
  const { t, i18n } = useTranslation(['homepage', 'language']);
  const languageMenuRef = useRef(null);
  const languageItems = Object.entries(translations).map(([language, displayLanguage]) => ({
    label: displayLanguage.nativeName,
    command: () => {
      i18n.changeLanguage(language).then(() => {
        window.location = window.location.origin + '/' + displayLanguage.language.langCode;
      });
    },
  }));

  const viewportRef = useRef(null);
  const { inViewport, enterCount } = useInViewport(viewportRef);

  return (
    <div className={`header ${inViewport && enterCount === 1 ? 'header--animated' : ''}`} ref={viewportRef}>
      <div className='header__logo'>
        <a href='/'>
          <img src={CDN_LINK + Logo} alt='Logo' />
        </a>
      </div>
      <div className='header__action-options header-action-options'>
        <div className='header-action-options__language-icon'>
          <Menu model={languageItems} popup ref={languageMenuRef} appendTo='self' />
          <img src={CDN_LINK + LanguageIcon} alt='Language Icon' onClick={(event) => languageMenuRef.current.toggle(event)} />
        </div>
        <a href={`/${t('langCode', { ns: 'language' })}/user/login`}>
          <Button text={t('heroSection.login')} />
        </a>
      </div>
    </div>
  );
};

export { Header };
