import './App.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AboutUs } from './components/AboutUs/AboutUs';
import { ContactUs } from './components/ContactUs/ContactUs';
import { ContactUsEducation } from './components/ContactUs/ContactUsEducation';
import { ContactUsCorporateTraining } from './components/ContactUs/ContactUsCorporateTraining';
import { ContactUsSuccess } from './components/ContactUs/ContactUsSuccess';
import { GeneralTerms } from './components/GeneralTerms/GeneralTerms';
import { PersonalData } from './components/PersonalData/PersonalData';
import { HeroSlide } from './components/HeroSlide/HeroSlide';
import { IntroSlide } from './components/IntroSlide/IntroSlide';
import { LanguagesSlide } from './components/LanguagesSlide/LanguagesSlide';
import { LegalNotices } from './components/LegalNotices/LegalNotices';
import { ReferencesSlide } from './components/ReferencesSlide/ReferencesSlide';
import { SolutionSlide } from './components/SolutionSlide/SolutionSlide';
import { Modal } from './shared/components/Modal/Modal';
import { TimelineMenu } from './shared/components/TimelineMenu/TimelineMenu';
import { IS_AUTO_SCROLL_ENABLED } from './shared/config';

const App = () => {
  const { t } = useTranslation(['homepage', 'contactUsForms']);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTimelineMenuVisible, setIsTimelineMenuVisible] = useState(false);
  const [isSolutionSliderCoveringTimelineMenu, setIsSolutionSliderCoveringTimelineMenu] = useState(false);
  const isThrottling = useRef(null);
  const shouldScrollOnNextSlideChange = useRef(true);

  const handleSolutionSlideChange = (slideIndex) => {
    if (slideIndex === 0) {
      setIsSolutionSliderCoveringTimelineMenu(false);
    } else {
      setIsSolutionSliderCoveringTimelineMenu(true);
    }
  };

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isLegalNoticesOpen, setIsLegalNoticesOpen] = useState(false);
  const [isGeneralTermsOpen, setIsGeneralTermsOpen] = useState(false);
  const [isContactEducationOpen, setIsContactEducationOpen] = useState(false);
  const [isContactCorporateOpen, setIsContactCorporateOpen] = useState(false);
  const [isContactUsSuccessOpen, setIsContactUsSuccessOpen] = useState(false);
  const [isPersonalDataOpen, setIsPersonalDataOpen] = useState(false);

  const openContactUsModalHandler = () => {
    setIsContactUsOpen(true);
  };

  const openAboutUsModalHandler = () => {
    setIsAboutUsOpen(true);
  };

  const openLegalNoticesModalHandler = () => {
    setIsLegalNoticesOpen(true);
  };

  const openGeneralTermsModalHandler = () => {
    setIsGeneralTermsOpen(true);
  };

  const openPersonalDataModalHandler = () => {
    setIsPersonalDataOpen(true);
  }

  const openContactEducationHandler = () => {
    setIsContactUsOpen(false);
    setIsContactEducationOpen(true);
  };
  
  const openContactCorporateModalHandler = () => {
    setIsContactUsOpen(false);
    setIsContactCorporateOpen(true);
  };

  const openContactUsSuccessModalHandler = () => {
    setIsContactUsSuccessOpen(true);
    setIsContactEducationOpen(false)
    setIsContactCorporateOpen(false);
  };

  const closeModalHandler = () => {
    setIsContactUsOpen(false);
    setIsAboutUsOpen(false);
    setIsLegalNoticesOpen(false);
    setIsGeneralTermsOpen(false);
    setIsContactEducationOpen(false)
    setIsContactCorporateOpen(false);
    setIsContactUsSuccessOpen(false);
    setIsPersonalDataOpen(false);
  };

  const sections = [
    <HeroSlide openContactUsModal={openContactUsModalHandler} />,
    <IntroSlide />,
    <ReferencesSlide />,
    <LanguagesSlide />,
    <SolutionSlide
      onSlideChange={handleSolutionSlideChange}
      openContactUsModal={openContactUsModalHandler}
      openAboutUsPage={openAboutUsModalHandler}
      openLegalNoticesPage={openLegalNoticesModalHandler}
      openPersonalDataPage={openPersonalDataModalHandler}
      openGeneralTermsPage={openGeneralTermsModalHandler}
    />,
  ];

  const sectionRefs = useRef(sections);

  const getVisibleSectionInfo = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let indexOfFirstVisibleSectionOnScreen = 0;
    let isBetweenSections = false;
    let sumOfPreviousSections = 0;

    for (let index = 0; index < sectionRefs.current.length; index++) {
      const sectionRef = sectionRefs.current[index];
      sumOfPreviousSections += sectionRef.scrollHeight;

      if (scrollTop + 5 > sumOfPreviousSections) {
        indexOfFirstVisibleSectionOnScreen = index + 1;
      } else {
        isBetweenSections = sumOfPreviousSections - scrollTop < window.innerHeight;
        break;
      }
    }

    const largestVisibleSection = isBetweenSections
      ? sumOfPreviousSections - scrollTop > window.innerHeight / 2
        ? indexOfFirstVisibleSectionOnScreen
        : indexOfFirstVisibleSectionOnScreen + 1
      : indexOfFirstVisibleSectionOnScreen;

    return { indexOfFirstVisibleSectionOnScreen, isBetweenSections, largestVisibleSection };
  };

  const handleTimelineMenuClick = useCallback((slideIndex) => {
    shouldScrollOnNextSlideChange.current = true;
    setCurrentSlide(slideIndex);
  }, []);

  useEffect(() => {
    if (currentSlide === 0) {
      setIsTimelineMenuVisible(false);
    } else if (currentSlide === 4) {
      setIsTimelineMenuVisible(!isSolutionSliderCoveringTimelineMenu);
    } else {
      setIsTimelineMenuVisible(true);
    }
  }, [currentSlide, isSolutionSliderCoveringTimelineMenu]);

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, sections.length);
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    isThrottling.current = false;

    const startThrottling = (duration) => {
      isThrottling.current = true;
      const DEFAULT_THROTTLE_DURATION = IS_AUTO_SCROLL_ENABLED ? 1000 : 500;

      setTimeout(() => {
        isThrottling.current = false;
      }, duration || DEFAULT_THROTTLE_DURATION);
    };

    const handleScroll = (event) => {
      if (isThrottling.current) {
        return;
      }

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const isAtStart = scrollTop === 0;
      const isScrollDown = scrollTop > lastScrollTop;
      const isScrollUp = scrollTop < lastScrollTop;

      const { indexOfFirstVisibleSectionOnScreen, isBetweenSections, largestVisibleSection } = getVisibleSectionInfo();

      if (!isScrollDown && !isScrollUp) {
        setCurrentSlide(largestVisibleSection);

        return;
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

      if (!isBetweenSections && !isAtStart) {
        return;
      }
      startThrottling();

      if (isScrollDown) {
        if (IS_AUTO_SCROLL_ENABLED) {
          event.preventDefault();
        } else if (largestVisibleSection !== indexOfFirstVisibleSectionOnScreen + 1) {
          return;
        }

        setTimeout(() => {
          startThrottling();
          setCurrentSlide(indexOfFirstVisibleSectionOnScreen + 1);
        }, 0);
      } else {
        if (IS_AUTO_SCROLL_ENABLED) {
          event.preventDefault();
        } else if (largestVisibleSection !== indexOfFirstVisibleSectionOnScreen) {
          return;
        }

        setTimeout(() => {
          startThrottling();
          setCurrentSlide(indexOfFirstVisibleSectionOnScreen);
        }, 0);
      }
    };

    document.addEventListener('DOMMouseScroll', handleScroll, false);
    document.addEventListener('onwheel', handleScroll, { passive: false });
    document.addEventListener('mousewheel', handleScroll, { passive: false });
    document.addEventListener('touchmove', handleScroll, { passive: false });

    return () => {
      document.removeEventListener('DOMMouseScroll', handleScroll, false);
      document.removeEventListener('onwheel', handleScroll, { passive: false });
      document.removeEventListener('mousewheel', handleScroll, { passive: false });
      document.removeEventListener('touchmove', handleScroll, { passive: false });
    };
  }, [sections.length]);

  useEffect(() => {
    if (!shouldScrollOnNextSlideChange.current) {
      return;
    } else if (!IS_AUTO_SCROLL_ENABLED) {
      shouldScrollOnNextSlideChange.current = false;
    }

    requestAnimationFrame(() => {
      window.scrollTo({
        top: sectionRefs.current[currentSlide].offsetTop,
        behavior: 'smooth',
      });
    });
  }, [currentSlide]);

  useEffect(() => {
    document.title = t('seo.title');
    document.querySelector('meta[name="description"]').content = t('seo.description');
  }, [t]);

  const modals = [
    {
      class: 'custom-modal',
      visible: isContactUsOpen,
      onHide: closeModalHandler,
      header: t('contactDialog.title'),
      body: <ContactUs
              openContactModals={[openContactEducationHandler, openContactCorporateModalHandler]}
            />
    },
    {
      class: 'custom-modal custom-modal--bigger',
      visible: isAboutUsOpen,
      onHide: closeModalHandler,
      header: '',
      body: <AboutUs />
    },
    {
      class: 'custom-modal custom-modal--bigger',
      visible: isLegalNoticesOpen,
      onHide: closeModalHandler,
      header: '',
      body: <LegalNotices />
    },
    {
      class: 'custom-modal custom-modal--bigger',
      visible: isGeneralTermsOpen,
      onHide: closeModalHandler,
      header: '',
      body: <GeneralTerms />
    },
    {
      class: 'custom-modal custom-modal--bigger',
      visible: isPersonalDataOpen,
      onHide: closeModalHandler,
      header: '',
      body: <PersonalData />
    },
    {
      class: 'custom-modal contact-us-form',
      visible: isContactEducationOpen,
      onHide: closeModalHandler,
      header: t('title190672', { ns: 'contactUsForms' }),
      body: <ContactUsEducation onSuccessSubmit={openContactUsSuccessModalHandler} />
    },
    {
      class: 'custom-modal contact-us-form',
      visible: isContactCorporateOpen,
      onHide: closeModalHandler,
      header: t('title190767', { ns: 'contactUsForms' }),
      body: <ContactUsCorporateTraining onSuccessSubmit={openContactUsSuccessModalHandler} />
    },
    {
      class: 'custom-modal contact-us-success',
      visible: isContactUsSuccessOpen,
      onHide: closeModalHandler,
      header: '',
      body: <ContactUsSuccess onClickCloseButton={closeModalHandler} />
    }
  ];

  return (
    <>
      <div>
        {sections.map((Section, i) => (
          <div key={i} ref={(ref) => (sectionRefs.current[i] = ref)} className='scrollable-section'>
            {Section}
          </div>
        ))}
      </div>
      <TimelineMenu
        currentSlide={currentSlide}
        onSlideChange={handleTimelineMenuClick}
        isVisible={isTimelineMenuVisible}
      />
      {modals.map((popup, index) => (
        <Modal
        key={index}
        customizeClass={popup.class}
        visible={popup.visible}
        onHide={popup.onHide}
        header={popup.header}
        >
          {popup.body}
        </Modal>
      ))}
    </>
  );
};

export { App };
