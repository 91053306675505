import React from 'react';

const VideoSVG = () => {
  return (
    <svg
      style={{
        position: 'relative',
        width: '100%',
        height: 'auto',
      }}
      width='188px'
      viewBox='0 0 188 406'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Group 19</title>
      <defs>
        <circle id='path-1' cx='153' cy='204' r='153'></circle>
        <filter x='-10.1%' y='-9.5%' width='120.3%' height='120.3%' filterUnits='objectBoundingBox' id='filter-2'>
          <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='10' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0'
            type='matrix'
            in='shadowBlurOuter1'
          ></feColorMatrix>
        </filter>
        <filter x='-56.6%' y='-25.9%' width='213.2%' height='152.7%' filterUnits='objectBoundingBox' id='filter-3'>
          <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='10' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0.0862745098   0 0 0 0 0.0901960784   0 0 0 0 0.215686275  0 0 0 0.4 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
            <feMergeNode in='SourceGraphic'></feMergeNode>
          </feMerge>
        </filter>
        <linearGradient x1='50%' y1='-26.1851411%' x2='50%' y2='100%' id='linearGradient-4'>
          <stop stopColor='#2A2B4F' offset='0%'></stop>
          <stop stopColor='#121333' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-5'>
          <stop stopColor='#E66783' offset='0%'></stop>
          <stop stopColor='#CE6CC8' offset='100%'></stop>
        </linearGradient>

        <rect id='path-6' x='0' y='0' width='190' height='408' rx='8'></rect>

        <clipPath id='cut-off-edges'>
          <rect x='1' y='1' width='188' height='406' rx='7'></rect>
        </clipPath>

        <clipPath id='limit-container'>
          <rect x='-1' y='-1' width='192' height='410' rx='8'></rect>
        </clipPath>

        <mask id='video-stroke'></mask>

        <filter x='-51.3%' y='-23.4%' width='202.6%' height='147.8%' filterUnits='objectBoundingBox' id='filter-7'>
          <feMorphology radius='2.5' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
          <feOffset dx='0' dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='30' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'></feComposite>
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0 0'
            type='matrix'
            in='shadowBlurOuter1'
          ></feColorMatrix>
        </filter>
        <filter x='-6.9%' y='-42.9%' width='113.7%' height='185.8%' filterUnits='objectBoundingBox' id='filter-8'>
          <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.6 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
            <feMergeNode in='SourceGraphic'></feMergeNode>
          </feMerge>
        </filter>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-9'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-10'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-11'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-12'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-13'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-14'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <linearGradient x1='50%' y1='205.703883%' x2='50%' y2='0%' id='linearGradient-15'>
          <stop stopColor='#CE6CC8' offset='0%'></stop>
          <stop stopColor='#E66783' offset='100%'></stop>
        </linearGradient>
        <path
          d='M3.41105199,33.6208219 C2.70909037,33.6208219 2.0396835,33.4397362 1.47472308,33.0762082 C0.344802257,32.3498305 -0.193029204,31.0083039 0.0626611628,29.5555485 L3.6592819,9.15728999 C3.91497226,7.70521286 4.92756037,6.36300799 6.31317157,5.63663032 C7.69810454,4.91093087 9.24852411,4.91093087 10.3791232,5.63663032 L26.2448122,15.8357596 C27.374733,16.561459 27.9132427,17.9036639 27.6568741,19.3564193 C27.4005055,20.8084964 26.3892738,22.1513795 25.0036626,22.8777572 L5.54067468,33.0762082 C4.84752997,33.4397362 4.11301361,33.6208219 3.41105199,33.6208219'
          id='path-16'
        ></path>
        <filter x='-9.0%' y='-5.3%' width='118.0%' height='121.0%' filterUnits='objectBoundingBox' id='filter-17'>
          <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='0.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0'
            type='matrix'
            in='shadowBlurOuter1'
          ></feColorMatrix>
        </filter>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-18'>
          <stop stopColor='#BB4660' offset='0%'></stop>
          <stop stopColor='#B860B3' offset='100%'></stop>
        </linearGradient>
      </defs>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-19'>
          <g id='Group' filter='url(#filter-3)' clipPath='url(#limit-container)'>
            <use href='#path-6' fill='url(#linearGradient-4)'></use>
            <g id='LA-dark-transparent' filter='url(#filter-8)' transform='translate(18.883759, 196.448017)'>
              <polyline
                id='Fill-1'
                fill='#FFFFFF'
                points='3.39918303 0.0257849608 7.51228716 0.0257849608 4.11310413 18.3605036 0 18.3605036 3.39918303 0.0257849608'
              ></polyline>
              <path
                d='M13.0282543,3.14777966 L8.91555307,3.14777966 L9.49410313,0.0257849608 L13.6072073,0.0257849608 L13.0282543,3.14777966 Z M10.2080242,18.3605036 L6.0949201,18.3605036 L8.64481098,4.60946462 L12.7571093,4.60946462 L10.2080242,18.3605036 L10.2080242,18.3605036 Z'
                id='Fill-2'
                fill='#FFFFFF'
              ></path>
              <path
                d='M14.7397311,4.60946462 L18.5800787,4.60946462 L18.3089337,6.07155248 L18.3584892,6.07155248 C18.9035993,5.40235217 20.0055035,4.13888909 22.4582979,4.13888909 C23.7709136,4.13888909 25.131876,4.68359639 25.7555498,5.3282204 C26.3796264,5.97203864 27.0198186,7.06225901 26.5190263,9.76283076 L24.9251935,18.3605036 L20.8124922,18.3605036 L22.2044772,10.8530511 C22.3833604,9.88692089 22.732666,8.00381297 20.5768016,8.00381297 C18.223521,8.00381297 17.846416,10.0355873 17.6904975,10.8780303 L16.3033472,18.3605036 L12.1902431,18.3605036 L14.7397311,4.60946462'
                id='Fill-3'
                fill='#FFFFFF'
              ></path>
              <path
                d='M34.2512915,14.9661553 C36.2335103,14.9661553 37.8877767,13.5286437 38.2548095,11.5464248 C38.6637429,9.34140781 37.4002798,8.00381297 35.5671302,8.00381297 C33.8081124,8.00381297 31.9850351,9.41594246 31.6127648,11.4227376 C31.2038314,13.6277546 32.5414262,14.9661553 34.2512915,14.9661553 Z M41.4122585,16.699791 C41.0311246,18.7569474 40.6387097,20.0699659 39.7539632,21.2339152 C38.3672158,22.9687596 35.5844545,24.48 32.1901062,24.48 C27.4082046,24.48 26.1322519,21.6057827 26.2224993,19.648543 L30.9798246,19.648543 C31.3218782,21.0119228 32.8576949,21.0119228 33.1304514,21.0119228 C33.6505824,21.0119228 36.4845108,20.9619644 37.168618,17.2698804 L37.2471816,16.8488603 L37.1976261,16.8488603 C36.6295512,17.6417478 35.2242708,18.8065029 33.0688093,18.8065029 C29.3275726,18.8065029 26.6552032,15.981841 27.472667,11.571404 C28.3449239,6.86403713 32.2154882,4.16346538 35.7830793,4.16346538 C37.3197018,4.16346538 38.7298168,4.70857557 39.2527681,5.89790688 L39.3023235,5.89790688 L39.5408344,4.60946462 L43.6539385,4.60946462 L41.4122585,16.699791 L41.4122585,16.699791 Z'
                id='Fill-4'
                fill='#FFFFFF'
              ></path>
              <path
                d='M55.7724672,18.3605036 L51.882967,18.3605036 L52.1537091,16.8988187 L52.1041537,16.8988187 C50.9353697,18.6578364 49.2230872,18.8065029 48.1828252,18.8065029 C47.3403822,18.8065029 45.3948263,18.608281 44.4657619,17.0716585 C43.6281536,15.7090844 43.663205,14.717975 44.0125107,12.83527 L45.5370465,4.60946462 L49.6501506,4.60946462 L48.2400355,12.2156252 C48.0655842,13.1571791 47.7303797,14.9661553 49.9353967,14.9661553 C51.124728,14.9661553 52.4039038,14.3469133 52.8080025,12.1660697 L54.209254,4.60946462 L58.3219552,4.60946462 L55.7724672,18.3605036'
                id='Fill-5'
                fill='#FFFFFF'
              ></path>
              <path
                d='M65.267779,14.9907316 C67.8442606,14.9907316 69.1157815,12.8106937 69.3639618,11.4722931 C69.6713668,9.81238623 68.8716302,7.97883379 66.5429259,7.97883379 C64.1888396,7.97883379 62.7811419,9.96105265 62.4914639,11.5218486 C62.252953,12.8106937 62.7900054,14.9907316 65.267779,14.9907316 Z M72.1995017,18.3605036 L68.0868004,18.3605036 L68.3623772,16.8734366 L68.3128217,16.8734366 C67.3712678,18.2114343 65.5465789,18.8306763 63.9358246,18.8306763 C59.575346,18.8306763 57.6571867,15.4121545 58.3876263,11.4722931 C59.223623,6.96274518 62.993062,4.13888909 66.6601669,4.13888909 C68.9147394,4.13888909 69.9763546,5.22870657 70.315588,6.07155248 L70.3651434,6.07155248 L70.6362884,4.60946462 L74.7489896,4.60946462 L72.1995017,18.3605036 L72.1995017,18.3605036 Z'
                id='Fill-6'
                fill='#FFFFFF'
              ></path>
              <path
                d='M81.7673337,14.9649466 C84.3438153,14.9649466 85.6153362,12.7849087 85.8635164,11.4465081 C86.1713244,9.78660127 85.3715877,7.95304883 83.0424805,7.95304883 C80.6883942,7.95304883 79.2806965,9.93526769 78.9914215,11.4960636 C78.7525077,12.7849087 79.2895601,14.9649466 81.7673337,14.9649466 Z M88.6990563,18.3347187 L84.5863551,18.3347187 L84.8619318,16.8480545 L84.8123764,16.8480545 C83.8708224,18.1856493 82.0465364,18.8052942 80.4357822,18.8052942 C76.0753036,18.8052942 74.1571442,15.3863695 74.8875838,11.4465081 C75.7235806,6.93696022 79.4930195,4.11310413 83.1597215,4.11310413 C85.4146969,4.11310413 86.4759092,5.20292161 86.8151426,6.04576752 L86.865101,6.04576752 L87.1358431,4.58367966 L91.2485443,4.58367966 L88.6990563,18.3347187 L88.6990563,18.3347187 Z'
                id='Fill-7'
                fill='url(#linearGradient-9)'
              ></path>
              <polyline
                id='Fill-8'
                fill='url(#linearGradient-10)'
                points='98.3104004 4.58367966 100.292619 4.58367966 99.7136663 7.70527147 97.7314475 7.70527147 95.7613153 18.3347187 91.6482112 18.3347187 93.6187462 7.70527147 91.5869719 7.70527147 92.1659249 4.58367966 94.1976992 4.58367966 95.0473942 0 99.1604983 0 98.3104004 4.58367966'
              ></polyline>
              <polyline
                id='Fill-9'
                fill='url(#linearGradient-11)'
                points='106.016075 4.58367966 107.998294 4.58367966 107.419341 7.70527147 105.437122 7.70527147 103.46699 18.3347187 99.3542884 18.3347187 101.324823 7.70527147 99.2930492 7.70527147 99.8720021 4.58367966 101.903776 4.58367966 102.753069 0 106.866173 0 106.016075 4.58367966'
              ></polyline>
              <path
                d='M113.754786,14.9649466 C116.330865,14.9649466 117.602386,12.7849087 117.850969,11.4465081 C118.158374,9.78660127 117.358637,7.95304883 115.029933,7.95304883 C112.675847,7.95304883 111.268149,9.93526769 110.978471,11.4960636 C110.739557,12.7849087 111.27661,14.9649466 113.754786,14.9649466 Z M120.686509,18.3347187 L116.573405,18.3347187 L116.849384,16.8480545 L116.799426,16.8480545 C115.857872,18.1856493 114.033586,18.8052942 112.422832,18.8052942 C108.062353,18.8052942 106.144194,15.3863695 106.875036,11.4465081 C107.71063,6.93696022 111.480069,4.11310413 115.147174,4.11310413 C117.401747,4.11310413 118.463362,5.20292161 118.802595,6.04576752 L118.852151,6.04576752 L119.122893,4.58367966 L123.235997,4.58367966 L120.686509,18.3347187 L120.686509,18.3347187 Z'
                id='Fill-10'
                fill='url(#linearGradient-12)'
              ></path>
              <path
                d='M137.562766,13.106415 C136.240883,16.2284097 133.01736,18.780315 129.301103,18.780315 C125.138846,18.780315 122.549069,15.5100568 123.306905,11.4219318 C124.055475,7.38295946 127.808798,4.11310413 131.9215,4.11310413 C135.563625,4.11310413 138.065975,6.51634305 138.134869,9.88611511 L133.972613,9.88611511 C133.701468,8.94415826 133.067319,7.95304883 131.357857,7.95304883 C129.41915,7.85434078 127.807187,9.46428926 127.439751,11.4465081 C127.067884,13.4537062 128.105325,14.9399674 130.062565,14.9399674 C131.772027,14.9399674 132.773612,13.948858 133.37553,13.106415 L137.562766,13.106415'
                id='Fill-11'
                fill='url(#linearGradient-13)'
              ></path>
              <polyline
                id='Fill-12'
                fill='url(#linearGradient-14)'
                points='141.034066 0 145.14717 0 143.373648 9.5638031 143.423204 9.5638031 148.162399 4.58367966 153.043009 4.58367966 146.744226 10.6290443 150.345257 18.3347187 145.687043 18.3347187 143.023537 11.7192647 142.973981 11.7192647 141.747584 18.3347187 137.63448 18.3347187 141.034066 0'
              ></polyline>
              <polyline
                id='Fill-13'
                fill='url(#linearGradient-15)'
                points='140.621507 24.3841122 136.508 24.3796804 137.289203 20.1678682 141.402307 20.1718971 140.621507 24.3841122'
              ></polyline>
            </g>
            <g id='Group-2' transform='translate(81.463668, 155.639178)'>
              <g id='Fill-14'>
                <use fill='black' fillOpacity='1' filter='url(#filter-17)' href='#path-16'></use>
                <use fill='#FFFFFF' fillRule='evenodd' href='#path-16'></use>
              </g>
              <path
                d='M9.72333462,-0.354773612 C8.10093222,0.495482391 6.91808248,2.06513656 6.61374232,3.79276321 L3.10464165,23.6930399 C2.78712959,25.4963011 3.47452413,27.2103779 4.92694591,28.1438224 C5.63536744,28.5997981 6.47526633,28.8336259 7.35713037,28.8336259 C8.21468873,28.8336259 9.0892106,28.6118623 9.89737973,28.1883038 L28.8862235,18.2381171 C30.5082535,17.3880562 31.6911431,15.8186469 31.9962964,14.0910988 C32.3137837,12.287979 31.6261089,10.5738143 30.174279,9.640176 L14.6943406,-0.310582752 C13.2732748,-1.22244061 11.3797142,-1.22244061 9.72333462,-0.354773612 Z M13.6142376,1.3726812 L29.0926572,11.3224644 C29.8452076,11.8064122 30.208402,12.7117457 30.0266914,13.7437431 C29.8313219,14.8497773 29.0401908,15.8994177 27.9578933,16.4666197 L8.9690328,26.4168151 C8.44312135,26.6924434 7.8863701,26.8336259 7.35713037,26.8336259 C6.84965298,26.8336259 6.38621006,26.7046034 6.00882804,26.4617015 C5.25544356,25.9775151 4.89251396,25.0725192 5.07429807,24.040104 L8.58338456,4.13990783 C8.77827741,3.03357323 9.56919632,1.98401544 10.6515478,1.4167851 C11.6984022,0.86840779 12.8283633,0.86840779 13.6142376,1.3726812 Z'
                id='Fill-15'
                fill='url(#linearGradient-18)'
                fillRule='nonzero'
              ></path>
            </g>
            <g id='Rectangle-Copy'>
              <use stroke='url(#linearGradient-5)' strokeWidth='2' fillRule='evenodd' href='#path-6'></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { VideoSVG };
