import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as translations from '../assets/translations.json';

const mappedTranslations = {};

Object.entries(translations).forEach(([key, value]) => {
  mappedTranslations[key] = { translation: value };
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'cookie', 'subdomain'],
      lookupCookie: 'anon_lang',
      caches: ['cookie'],
      cookieMinutes: 60*24*30,
      cookieDomain: '.lingua-attack.com',
      cookieOptions: { sameSite: 'none', secure: true }
    },
    resources: translations,
    fallbackLng: 'en-EA',
    lowerCaseLng: true,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
