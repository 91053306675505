import './TabItem.scss';

import {
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import { CDN_LINK } from '../../../shared/constants';

const TabItem = forwardRef(({ icon, altIcon, title, paragraphs = [], video, handleUploadOfVideosLoaded }, ref) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!video || !videoRef.current) {
      return;
    }

    videoRef.current.play();
  }, [video]);

  return (
    <div className='tab-panel' ref={ref}>
      <div className='tab-panel__introduction tab-panel-introduction'>
        <div className='tab-panel-introduction__title tab-panel-introduction-title'>
          <div className='tab-panel-introduction-title__image'>
            <img src={CDN_LINK + icon} alt={altIcon} />
          </div>
          <div className='tab-panel-introduction-title__text'>{title}</div>
        </div>
        <div className='tab-panel-introduction__text'>
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
      {video && (
        <div className='tab-panel__video tab-panel-video'>
          <video preload='metadata' ref={videoRef} autoPlay loop muted playsInline onLoadedData={handleUploadOfVideosLoaded}>
            <source src={CDN_LINK + video} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
});

export { TabItem };
