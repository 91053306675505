import './ContactUs.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/components/Button/Button';
import { BUTTON_TYPES } from '../../shared/constants';

const ContactUs = ({openContactModals = []}) => {
    const { t } = useTranslation(['homepage']);

    return (
        <div className='contact-us'>
            <p>{t('contactDialog.subtitle')}</p>
            <div className='contact-us__buttons contact-us-buttons'>
                {t('contactDialog.buttons', { returnObjects: true }).map((button, index) => (
                    <div className='contact-us-buttons__btn' key={index} >
                        <Button text={button} type={BUTTON_TYPES.MAIN} onClick={openContactModals[index]} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export { ContactUs };