import React from 'react';
import { useRef } from "react";

import { useTranslation } from 'react-i18next';

import './ContactUsForms.scss';
import { CONTACT_LINKS } from '../../shared/constants';

import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';

const ContactUsEducation = ({onSuccessSubmit}) => {

    const { t } = useTranslation(['contactUsForms', 'language']);
    
    const educationalTypes = [
        {name: t('primary'), code: ['Primary']},
        {name: t('secondary'), code: ['Secondary']},
        {name: t('university'), code: ['University']},
        {name: t('languageInstitue'), code: ['Language Institue']},
    ];
    
    const instructionalLevels = [
        {name: t('stateSchool'), code: 'state'},
        {name: t('private'), code: 'private'},
    ];

    const first_name = useRef();
    const last_name = useRef();
    const position = useRef();
    const email_address = useRef();
    const school_name = useRef();
    const city = useRef();
    const country = useRef();
    const type_of_educational_establishment_hidden_input = useRef();
    const number_of_students_in_your_school = useRef();

    const requiredFieldsOrderFromForm = [
        {'first_name': first_name},
        {'last_name': last_name},
        {'position': position},
        {'email_address': email_address},
        {'school_name': school_name},
        {'city': city},
        {'country': country},
        {'type_of_educational_establishment': type_of_educational_establishment_hidden_input},
        {'number_of_students_in_your_school': number_of_students_in_your_school}
    ];

    const putFocusOnField = (fieldName) => {
        if (fieldName.current) {
            fieldName.current.focus();
        }
    };

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            position: '',
            email_address: '',
            phone_number: '',
            school_name: '',
            city: '',
            country: '',
            type_of_educational_establishment: [],
            organization_type: '',
            number_of_students_in_your_school: '',
            message: ''
        },
        onSubmit: (data) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            const fetchURL = `${window.location.origin}/${t('langCode', { ns: 'language' })}/${CONTACT_LINKS.EDUCATION}`;

            fetch(fetchURL, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                    let errorFields = Object.keys(res);

                    if (res.status === "success") {
                        onSuccessSubmit();
                        formik.resetForm();
                    } else {
                        Object.entries(res).forEach( ([key, value]) => { formik.setFieldError(key, value) } )

                        for (let i=0; i<requiredFieldsOrderFromForm.length; i++) {
                            let requiredFieldName = Object.keys(requiredFieldsOrderFromForm[i])[0];
                            if (errorFields.indexOf(requiredFieldName) >= 0) {
                                let requiredField = requiredFieldsOrderFromForm[i];
                                putFocusOnField(requiredField[requiredFieldName]);
                                break;
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <div className='form-container-contact-field-block__error-message'><small className="p-error">{formik.errors[name]}</small></div>;
    };

    const validateNumberStudentsHandler = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            formik.setFieldValue('number_of_students_in_your_school', e.target.value);
        }
    };
    
    return (
        <>
        <div className="form-container">                                            
            <form onSubmit={formik.handleSubmit} className='form-container__contact form-container-contact'>
                <div className='form-container-contact__subtitle'>{t('yourInformation')}</div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('first_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="first_name">{t('firstName')}:&nbsp;*</label>
                        <InputText id="first_name" name="first_name" value={formik.values.first_name} ref={first_name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('first_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('last_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="last_name">{t('lastName')}:&nbsp;*</label>
                        <InputText id="last_name" name="last_name" value={formik.values.last_name} ref={last_name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('last_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('position')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="position">{t('jobFunction')}:&nbsp;*</label>
                        <InputText id="position" name="position" value={formik.values.position} ref={position} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('position') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('email_address')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="email_address">{t('emailAddress')}:&nbsp;*</label>
                        <InputText id="email_address" name="email_address" value={formik.values.email_address} ref={email_address} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email_address') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('phone_number')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="phone_number">{t('telephoneNumber')}:</label>
                        <InputText id="phone_number" name="phone_number" value={formik.values.phone_number} onChange={formik.handleChange} />
                    </div>
                </div>
                <div className='form-container-contact__subtitle'>{t('yourSchool')}</div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('school_name')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="school_name">{t('establishmentName')}:&nbsp;*</label>
                        <InputText id="school_name" name="school_name" value={formik.values.school_name} ref={school_name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('school_name') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('city')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="city">{t('cityTown')}:&nbsp;*</label>
                        <InputText id="city" name="city" value={formik.values.city} ref={city} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('city') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('country')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="country">{t('country')}:&nbsp;*</label>
                        <InputText id="country" name="country" value={formik.values.country} ref={country} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('country') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('type_of_educational_establishment')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="type_of_educational_establishment_hidden_input">{t('educationalEstablishment')}:&nbsp;*</label>
                        <InputText id="type_of_educational_establishment_hidden_input" name="type_of_educational_establishment_hidden_input" ref={type_of_educational_establishment_hidden_input} />
                        <SelectButton className='contact-us-select-button' id="type_of_educational_establishment" name="type_of_educational_establishment" optionLabel="name" optionValue="code" value={formik.values.type_of_educational_establishment} options={educationalTypes} onChange={formik.handleChange}></SelectButton>
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('organization_type')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="organization_type_input">{t('instructionalLevel')}:</label>
                        <Dropdown className='contact-us-dropdown' id="organization_type" inputId="organization_type_input" name="organization_type" value={formik.values.organization_type} onChange={formik.handleChange} options={instructionalLevels} optionLabel="name" optionValue="code" placeholder={t('select')} appendTo='self' />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('number_of_students_in_your_school')}
                    <div className='form-container-contact-field-block__field'>
                        <label htmlFor="number_of_students_in_your_school">{t('numberStudents')}:&nbsp;*</label>
                        <InputText id="number_of_students_in_your_school" name="number_of_students_in_your_school" value={formik.values.number_of_students_in_your_school} ref={number_of_students_in_your_school} onChange={validateNumberStudentsHandler} className={classNames({ 'p-invalid': isFormFieldValid('number_of_students_in_your_school') })} />
                    </div>
                </div>
                <div className="form-container-contact__field-block form-container-contact-field-block">
                    {getFormErrorMessage('message')}
                    <div className='form-container-contact-field-block__field form-container-contact-field-block-field--full-size'>
                        <label htmlFor="message">{t('whatCan')}:</label>
                        <InputTextarea id="message" rows={5} value={formik.values.message} onChange={formik.handleChange} />
                    </div>
                </div>
                <Button type="submit" label={t('send')} className="form-container-contact__btn" />
            </form>
        </div>
        </>
    );
};

export { ContactUsEducation };