import React from 'react';

import { Slide } from '../../shared/components/Slide/Slide';
import { SLIDE_TYPES } from '../../shared/constants';
import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';

const HeroSlide = React.memo(({openContactUsModal}) => {
  return (
    <Slide type={SLIDE_TYPES.SECONDARY}>
      <Header />
      <Content openContactUsModal={openContactUsModal} />
      <Footer />
    </Slide>
  );
});

export { HeroSlide };
