import './Content.scss';

import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

import { Button } from '../../shared/components/Button/Button';
import { BUTTON_TYPES } from '../../shared/constants';
import { Animation3D } from './Animation3D';

const Content = ({ openContactUsModal }) => {
  const { t } = useTranslation(['homepage']);
  const viewportRef = useRef(null);
  const { inViewport, enterCount } = useInViewport(viewportRef);

  return (
    <div className={`hero-content ${inViewport && enterCount === 1 ? 'hero-content--animated' : ''}`} ref={viewportRef}>
      <div className="hero-content__info hero-content-info">
        <div className="hero-content-info__title">{t('heroSection.title')}</div>
        {t('heroSection.subtitleParagraphs', { returnObjects: true }).map((paragraph, index) => {
          switch (index) {
            case 0:
              return (
                <div key={index} className="hero-content-info__subtitle hero-content-info__subtitle--highlighted">
                  {paragraph}
                </div>
              );
            default:
              return (
                <div key={index} className="hero-content-info__subtitle">
                  {paragraph}
                </div>
              );
          }
        })}

        <div className="hero-content-info__button">
          <Button text={t('footer.contactUs')} type={BUTTON_TYPES.MAIN} onClick={openContactUsModal} />
        </div>
      </div>
      <div className="hero-content__animation" id="animation-container">
        <Animation3D />
      </div>
    </div>
  );
};

export { Content };
