import './LanguagesSlide.scss';

import React, { useRef } from 'react';

import {
  Trans,
  useTranslation,
} from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

import CNFlag from '../../assets/flags/CN-flag.png';
import DEFlag from '../../assets/flags/DE-flag.png';
import ESFlag from '../../assets/flags/ES-flag.png';
import FRFlag from '../../assets/flags/FR-flag.png';
import PRFlag from '../../assets/flags/PR-flag.png';
import USUKFlag from '../../assets/flags/USUK-flag.png';
import ITFlag from '../../assets/flags/IT-flag.png';
import { Slide } from '../../shared/components/Slide/Slide';
import { Title } from '../../shared/components/Title/Title';
import { CDN_LINK } from '../../shared/constants';

const LanguagesSlide = React.memo(() => {
  const { t } = useTranslation(['homepage']);
  const viewportRef = useRef();
  const { enterCount } = useInViewport(viewportRef);

  return (
    <Slide className='languages-slide'>
      <section className='languages-slide__introduction'>
        <Title text={t('languagesSection.title')} />
        {t('languagesSection.paragraphs', { returnObjects: true }).map((paragraph, index) => (
          <p key={index}>
            <Trans defaults={paragraph} components={[<em />, <i />]} />
          </p>
        ))}
      </section>
      <section className={`flags-section slide-up-animation`} ref={viewportRef}>
        {enterCount > 0 && (
          <>
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + USUKFlag} alt='USUK Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + FRFlag} alt='FR Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + ESFlag} alt='ES Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + DEFlag} alt='DE Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + ITFlag} alt='IT Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + PRFlag} alt='PR Flag' />
            <img className='flags-section__flag slide-up-animation__element' src={CDN_LINK + CNFlag} alt='CN Flag' />
          </>
        )}
      </section>
    </Slide>
  );
});

export { LanguagesSlide };
