import './TimelineMenu.scss';

import { useTranslation } from 'react-i18next';

import { TimelineItem } from '../TimelineItem/TimelineItem';

const TimelineMenu = ({ currentSlide, onSlideChange, isVisible }) => {
  const styleClass = `timeline-menu ${isVisible ? '' : 'timeline-menu--invisible'}`;
  const { t } = useTranslation(['homepage']);
  const SLIDES = [
    { id: 1, name: t('timelineMenu.introduction') },
    { id: 2, name: t('timelineMenu.educationalContent') },
    { id: 3, name: t('timelineMenu.languages') },
    { id: 4, name: t('timelineMenu.solutions') },
  ];

  return (
    <div className={styleClass}>
      {SLIDES.map((slide) => (
        <TimelineItem
          key={slide.id}
          text={slide.name}
          index={slide.id}
          currentSlide={currentSlide}
          onClick={onSlideChange}
        />
      ))}
    </div>
  );
};

export { TimelineMenu };
