import './SwiperCarouselSlide.scss';

import React, { useState } from 'react';
import {
  useSwiper
} from 'swiper/react';

import { CDN_LINK } from '../../constants';
import { VideoSVG } from './VideoSVG';

const SwiperCarouselSlide = ({ id, title, paragraphs = [], paragraphTags = [], textColor, video, onLoadVideo = () => {} }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const swiper = useSwiper();

  const handleOnLoadedData = () => {
    onLoadVideo(id);
    setIsVideoLoaded(true);
  };

  const handleParagrahClick = (clickedIndex) => {
    swiper.slideTo(clickedIndex + 1);
  }

  return (
    <div className={`carousel-slide carousel-slide${id}`}>
      <div className='carousel-slide__info carousel-slide-info'>
        <div className='carousel-slide-info__title'>{title}</div>
        <div className={`carousel-slide-info__description carousel-slide-info__description--${textColor}-color `}>
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        {typeof paragraphTags !== 'string' && <div className='carousel-slide-info__tags'>
          {paragraphTags.map((tag, index) => (
            <span key={index} onClick={ () => handleParagrahClick(index)}>{tag}</span>
          ))}
        </div>}
      </div>
      {video && (
        <div className='carousel-slide__graphics carousel-slide-graphics'>
          <div
            className={
              isVideoLoaded
                ? 'carousel-slide-graphics__video'
                : 'carousel-slide-graphics__video carousel-slide-graphics__video--loading'
            }
          >
            {!isVideoLoaded && <VideoSVG video={video} />}
            <video preload='metadata' autoPlay loop muted playsInline onLoadedData={handleOnLoadedData}>
              <source src={CDN_LINK + video} type='video/mp4' />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export { SwiperCarouselSlide };
