import './TabsView.scss';

import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import {
  TabPanel,
  TabView,
} from 'primereact/tabview';
import { useTranslation } from 'react-i18next';

import {useWindowSize} from 'react-use';

import PVIcon from '../../../assets/references-tabs/PV-icon.svg';
import SBIcon from '../../../assets/references-tabs/SB-icon.svg';
import StarterLabsIcon
  from '../../../assets/references-tabs/Starterlabs-icon.svg';
import TabVideo1 from '../../../assets/references-tabs/tab-animation-1.mp4';
import TabVideo2 from '../../../assets/references-tabs/tab-animation-2.mp4';
import TabVideo3 from '../../../assets/references-tabs/tab-animation-3.mp4';
import TabVideo4 from '../../../assets/references-tabs/tab-animation-4.mp4';
import TabVideo5 from '../../../assets/references-tabs/tab-animation-5.mp4';
import TabVideo6 from '../../../assets/references-tabs/tab-animation-6.mp4';
import TeachersIcon from '../../../assets/references-tabs/Teachers-icon.svg';
import TestIcon from '../../../assets/references-tabs/test-icon.svg';
import VBIcon from '../../../assets/references-tabs/VB-icon.svg';
import { TabItem } from '../TabItem/TabItem';

const TAB_COLORS = { default: 'default', blue: 'blue', orange: 'orange', yellow: 'yellow', midBlue: 'midBlue' };

const TABS = [
  {
    id: 1,
    color: TAB_COLORS.blue,
    icon: VBIcon,
    altIcon: 'Video Boosters',
    video: TabVideo1,
  },
  {
    id: 2,
    color: TAB_COLORS.orange,
    icon: PVIcon,
    altIcon: 'Photo Vocabs',
    video: TabVideo2,
  },
  {
    id: 3,
    color: TAB_COLORS.yellow,
    icon: StarterLabsIcon,
    altIcon: 'Starter Labs',
    video: TabVideo3,
  },
  {
    id: 4,
    color: TAB_COLORS.midBlue,
    icon: SBIcon,
    altIcon: 'Skill Boosters',
    video: TabVideo4,
  },
  {
    id: 5,
    color: TAB_COLORS.default,
    icon: TestIcon,
    altIcon: 'Test',
    video: TabVideo5,
  },
  {
    id: 6,
    color: TAB_COLORS.default,
    icon: TeachersIcon,
    altIcon: 'Teacher',
    video: TabVideo6,
  }
];

const TabCustomHeader = React.forwardRef(({text}, ref) => {
  return <div ref={ref} >{text}</div>
})

const TabsView = ({ shouldStartRendering }) => {
  const { t } = useTranslation('homepage');
  const [tabIndex, setTabIndex] = useState(0);
  const tabsRef = useRef(Array(TABS.length).fill(null));
  const tabPanelsRef = useRef(Array(TABS.length).fill(null));
  const [highestTabHeight, setHighestTabHeight] = useState('auto');
  const [nrOfVideosLoaded, setNrOfVideosLoaded] = useState(0);

  const [showNextArrow, setShowNextArrrow] = useState(false);

  const windowSize = useWindowSize();

  const handleTabChange = (e) => {
    setTabIndex(e.index);
  };

  const getHighestTabHeight = useCallback(() => {
    const allHeights = tabsRef.current.filter(Boolean).map((element) => element.clientHeight);
    let max = parseInt(Math.max(...allHeights));

    return max ? max + 'px' : 'auto';
  }, []);

  const getTotalWidth = useCallback(() => {
    const allHeadersWidths = tabPanelsRef.current.filter(Boolean).map((element) => element.parentElement.parentElement.parentElement.clientWidth);
    const lastElementWidthToHide = allHeadersWidths[allHeadersWidths.length - 1] / 4;
    const totalHeadersWidth = allHeadersWidths.reduce((a, b) => a + b, 0);
    const availableWidth = tabPanelsRef.current[0]?.parentElement?.parentElement?.parentElement?.parentElement.clientWidth + lastElementWidthToHide;

    if(totalHeadersWidth > availableWidth && showNextArrow === false) {
      setShowNextArrrow(true);
    } else if(totalHeadersWidth < availableWidth && showNextArrow === true) {
      setShowNextArrrow(false);
    }
  }, [showNextArrow]);

  useLayoutEffect(() => {
    if (TABS.length === nrOfVideosLoaded) {

      setHighestTabHeight('auto');

      window.setTimeout(() => {
        setHighestTabHeight(getHighestTabHeight());
      }, 200)
    }
  }, [windowSize, getHighestTabHeight, nrOfVideosLoaded]);

  useLayoutEffect(() => {
    if (TABS.length === nrOfVideosLoaded) {
      getTotalWidth();
    }
  }, [windowSize, getTotalWidth, nrOfVideosLoaded]);

  const handleUploadOfVideosLoaded = () => {
    setNrOfVideosLoaded(nrOfVideosLoaded + 1);
  };

  return (
    <TabView
      activeIndex={tabIndex}
      className={`tabs-container tabs-container--${TABS[tabIndex].color}-color`}
      onTabChange={handleTabChange}
      scrollable={showNextArrow}
      renderActiveOnly={false}
      panelContainerStyle={{ height: highestTabHeight }}
      >
      {shouldStartRendering &&
        t('referencesSection.tabs', { returnObjects: true })
          .filter((_, index) => index <= TABS.length)
          .map((translationsTab, index) => (
            <TabPanel key={TABS[index].id} header={<TabCustomHeader text={translationsTab.tabText} ref={(ref) => (tabPanelsRef.current[index] = ref)} />} contentStyle={{ height: highestTabHeight }}>
              <TabItem
                ref={(ref) => (tabsRef.current[index] = ref)}
                icon={TABS[index].icon}
                altIcon={TABS[index].altIcon}
                title={translationsTab.title}
                paragraphs={translationsTab.paragraphs}
                video={TABS[index].video}
                handleUploadOfVideosLoaded={handleUploadOfVideosLoaded}
              />
            </TabPanel>
          ))}
    </TabView>
  );
};

export { TabsView };
