import './Footer.scss';

import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import ElLogo from '../../../assets/footer-logos/EL-logo.svg';
import GarLogo from '../../../assets/footer-logos/GAR-logo.svg';
import QualiopiLogo from '../../../assets/footer-logos/qualiopi-logo.png';
import UgapLogo from '../../../assets/footer-logos/UGAP-logo.png';
import EuLogo from '../../../assets/footer-logos/EU-logo.svg';
import IdfLogo from '../../../assets/footer-logos/IDF-logo.svg';
import PrvfLogo from '../../../assets/footer-logos/PRVF-logo.svg';
import QualiopiCertificate from '../../../docs/Certificat Qualiopi V.2 - ENTERTAINMENT LEARNING.pdf';
import { CDN_LINK, USER_MANUAL_LINK } from '../../../shared/constants';
import { getYear } from '../../utils';

const Footer = ({openAboutUsPage, openLegalNoticesPage, openGeneralTermsPage, openPersonalDataPage, openContactUsModal}) => {
  const { t } = useTranslation(['homepage', 'language']);
  const qualiopiText = t('footer.qualiopiText');
  const getSanitizedData = () => ({
    __html: DOMPurify.sanitize(qualiopiText)
  });

  const openUserManualPage = () => {
    window.location = `${window.location.origin}/${t('langCode', { ns: 'language' })}/${USER_MANUAL_LINK}`;
  }

  return (
    <div className='main-footer'>
      <div className='main-footer__links main-footer-links'>
        <div
          className='main-footer-links__link'
          onClick={openAboutUsPage}
        >
          {t('footer.aboutUs')}{' '}
        </div>
        <div
          className='main-footer-links__link'
          onClick={openLegalNoticesPage}
        >
          {t('footer.legalNotices')}
        </div>
        <div
          className='main-footer-links__link'
          onClick={openGeneralTermsPage}
        >
          {t('footer.generalConditionsAndTermsOfUse')}
        </div>
        <div
          className='main-footer-links__link'
          onClick={openPersonalDataPage}
        >
          {t('footer.personalData')}
        </div>
        <div
          className='main-footer-links__link'
          onClick={openUserManualPage}
        >
          {t('footer.userManual')}
        </div>
        <div className='main-footer-links__link'
          onClick={openContactUsModal} >
          {t('footer.contactUs')}
        </div>
      </div>
      <div className='main-footer__logos main-footer-logos'>
        <div className='main-footer-logos__images main-footer-logos-images'>
          <div className='main-footer-logos-images__container-qualiopi'>
            <div className='main-footer-logos-images__qualiopi'>
              <a href={QualiopiCertificate} target="_blank" rel="noopener noreferrer">
                <img src={CDN_LINK + QualiopiLogo} alt='Qualiopi Logo' />
              </a>
            </div>
            <div className='main-footer-logos-images__text' dangerouslySetInnerHTML={getSanitizedData()}></div>
          </div>
          <div className='main-footer-logos-images__ugap'>
            <img src={CDN_LINK + UgapLogo} alt='UGAP Logo' />
          </div>
          <div className='main-footer-logos-images__gar'>
            <img src={CDN_LINK + GarLogo} alt='GAR Logo' />
          </div>
          <div className='main-footer-logos-images__el'>
            <img src={CDN_LINK + ElLogo} alt='EL Logo' />
          </div>
          <div className='main-footer-logos-images__eu'>
            <img src={CDN_LINK + EuLogo} alt='EU Logo' />
          </div>
          <div className='main-footer-logos-images__idf'>
            <img src={CDN_LINK + IdfLogo} alt='IDF Logo' />
          </div>
          <div className='main-footer-logos-images__prvf'>
            <img src={CDN_LINK + PrvfLogo} alt='PRVF Logo' />
          </div>
        </div>
        <div className='main-footer-logos__text'>© {getYear(new Date())} Entertainment Learning</div>
      </div>
    </div>
  );
};

export { Footer };
