export const BUTTON_TYPES = { MAIN: 'main', SECONDARY: 'secondary' };
export const SLIDE_TYPES = { SECONDARY: 'secondary' };

export const MEDIUM_SCREEN_WIDTH = '900px';
export const LARGE_SCREEN_WIDTH = '1300px';
export const XLARGE_SCREEN_WIDTH = '1780px';

export const CDN_LINK = '';

export const CONTACT_LINKS = {
  EDUCATION: 'react-api/contact-forms-validate/webform_client_form_190672',
  CORPORATE: 'react-api/contact-forms-validate/webform_client_form_190767'
}

export const USER_MANUAL_LINK = 'anon-user-manual/category';

export const BROWSER_NAMES = {
  FIREFOX: 'Mozilla Firefox',
  SAMSUNGBROWSER: 'Samsung Internet',
  OPERA: 'Opera',
  TRIDENT: 'Microsoft Internet Explorer',
  EDGE: 'Microsoft Edge',
  CHROME: 'Google Chrome or Chromium',
  SAFARI: 'Apple Safari',
  UNKNOWN: 'unknown'
}
