import './Slide.scss';

import { forwardRef } from 'react';

import { SLIDE_TYPES } from '../../constants';

const Slide = forwardRef(({ children, type, className, ...props }, ref) => {
  const getSlideType = () => {
    switch (type) {
      case SLIDE_TYPES.SECONDARY:
        return 'slide slide--secondary';
      default:
        return 'slide slide--default';
    }
  };
  return (
    <div className={`${getSlideType()} ${className}`} ref={ref} {...props}>
      {children}
    </div>
  );
});

export { Slide };
