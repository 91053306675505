import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './SwiperCarousel.scss';

import {
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import {
  Navigation,
  Pagination,
} from 'swiper';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';

import SlideVideo1 from '../../../assets/solution-slide/mobile-animation-1.mp4';
import SlideVideo2 from '../../../assets/solution-slide/mobile-animation-2.mp4';
import SlideVideo3 from '../../../assets/solution-slide/mobile-animation-3.mp4';
import SlideVideo4 from '../../../assets/solution-slide/mobile-animation-4.mp4';
import SlideVideo5 from '../../../assets/solution-slide/mobile-animation-5.mp4';
import SlideVideo6 from '../../../assets/solution-slide/mobile-animation-6.mp4';
import { MEDIUM_SCREEN_WIDTH } from '../../constants';
import {
  SwiperCarouselSlide,
} from '../SwiperCarouselSlide/SwiperCarouselSlide';

const SLIDES_COLORS = { default: 'default', lightBlue: 'light-blue', lightPink: 'light-pink' };
const SLIDES_TEXT_COLORS = { default: 'default', grey: 'grey' };

const SLIDES = [
  {
    id: 1,
    color: SLIDES_COLORS.default,
    textColor: SLIDES_TEXT_COLORS.default,
    video: SlideVideo1,
  },
  {
    id: 2,
    color: SLIDES_COLORS.lightBlue,
    textColor: SLIDES_TEXT_COLORS.grey,
    video: SlideVideo2,
  },
  {
    id: 3,
    color: SLIDES_COLORS.lightPink,
    textColor: SLIDES_TEXT_COLORS.grey,
    video: SlideVideo3,
  },
  {
    id: 4,
    color: SLIDES_COLORS.default,
    textColor: SLIDES_TEXT_COLORS.default,
    video: SlideVideo4,
  },
  {
    id: 5,
    color: SLIDES_COLORS.lightBlue,
    textColor: SLIDES_TEXT_COLORS.grey,
    video: SlideVideo5,
  },
  {
    id: 6,
    color: SLIDES_COLORS.lightPink,
    textColor: SLIDES_TEXT_COLORS.grey,
    video: SlideVideo6,
  },
];

const SwiperCarousel = ({ onSlideChange, shouldStartRendering }) => {
  const { t } = useTranslation(['homepage']);
  const { width } = useWindowSize();
  const isSmallWidth = width < MEDIUM_SCREEN_WIDTH.slice(0, -2);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isMovingSlide, setIsMovingSlide] = useState(false);
  const [loadedVideoIds, setLoadedVideoIds] = useState([]);

  const handleSlideChange = ({ activeIndex }) => {
    setCurrentSlideIndex(activeIndex);
    setIsMovingSlide(false);
  };

  const handleBeforeSlideChangeStart = () => {
    setIsMovingSlide(true);
  };

  useEffect(() => {
    onSlideChange(currentSlideIndex);
  }, [currentSlideIndex, onSlideChange]);

  useEffect(() => {
    if (!isMovingSlide) {
      onSlideChange(currentSlideIndex);
      return;
    }

    onSlideChange(currentSlideIndex || 1);
  }, [isMovingSlide, currentSlideIndex, onSlideChange]);

  const handleLoadVideo = (slideId) => {
    if (!loadedVideoIds.find((id) => id === slideId)) {
      setLoadedVideoIds([...loadedVideoIds, slideId]);
    }
  };

  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={isSmallWidth ? 25 : 80}
        centeredSlides={true}
        pagination={{
          type: 'fraction',
        }}
        navigation={{
            prevEl: '.swiper-button-prev-container',
            nextEl: '.swiper-button-next-container',
        }}
        modules={[Pagination, Navigation]}
        className='solution-swiper'
        onSlideChange={handleSlideChange}
        onSlideResetTransitionEnd={handleSlideChange}
        onSliderMove={handleBeforeSlideChangeStart}
      >
        <div className='swiper-button-prev-container'>
          <div className='swiper-button-prev-container__text'>{t(`solutionSection.slide${currentSlideIndex}.title`)}</div>
          <div className='swiper-button-prev-container__image'></div>
        </div>
        <div className='swiper-button-next-container'>
          <div className="swiper-button-next-container__image"></div>
          <div className='swiper-button-next-container__text'>{t(`solutionSection.slide${currentSlideIndex + 2}.title`)}</div>
        </div>

        {SLIDES.map((slide, index) => (
          <SwiperSlide key={slide.id} className={`swiper-slide--${slide.color}-color`}>
            <SwiperCarouselSlide
              id={slide.id}
              title={t(`solutionSection.slide${index + 1}.title`)}
              paragraphs={t(`solutionSection.slide${index + 1}.paragraphs`, { returnObjects: true })}
              paragraphTags={t(`solutionSection.slide${index + 1}.school_categories`, { returnObjects: true })}
              textColor={slide.textColor}
              video={shouldStartRendering && slide.video}
              onLoadVideo={handleLoadVideo}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export { SwiperCarousel };
