import { Dialog } from 'primereact/dialog';

import './Modal.scss';

const Modal = ({customizeClass, header, visible, onHide, children}) => {
    return (
        <Dialog
            className={customizeClass}
            header={header}
            visible={visible}
            modal={true}
            onHide={onHide}
            dismissableMask={true}
            //blockScroll={true}
            draggable={false}
            >
            {children}
        </Dialog>
    );
};

export { Modal };