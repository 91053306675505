import './Button.scss';

import { BUTTON_TYPES } from '../../constants';

const Button = ({type, onClick, text}) => {
  const getButtonTypeClass = () => {
    switch (type) {
      case BUTTON_TYPES.SECONDARY:
        return 'button button--secondary';
      case BUTTON_TYPES.MAIN:
        return 'button button--main';
      default:
        return 'button button--default';
    }
  };

  return <div className={`${getButtonTypeClass()}`} onClick={onClick}>{text}</div>;
};

export { Button };
